import Vue from 'vue'
import axios from 'axios'
import FlashMessage from "@utils/flash-messages"
import { LMap, LMarker, LCircle, LPopup } from 'vue2-leaflet'
import CustomMap from '@components/CustomMap'
import CustomMapMarker from '@components/CustomMapMarker'
import ContactForm from '@components/ContactForm'
import CallbackRequestForm from '@components/CallbackRequestForm'
import "leaflet/dist/leaflet.css"
import mapboxgl from 'mapbox-gl'
import "mapbox-gl/dist/mapbox-gl.css"
import 'mapbox-gl-leaflet'
import vSelect from 'vue-select'

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

window.mapboxgl = mapboxgl

Vue.component('LMap', LMap)
Vue.component('LMarker', LMarker)
Vue.component('LCircle', LCircle)
Vue.component('LPopup', LPopup)
Vue.component('CustomMap', CustomMap)
Vue.component('CustomMapMarker', CustomMapMarker)
Vue.component('ContactForm', ContactForm)
Vue.component('CallbackRequestForm', CallbackRequestForm)
Vue.component('vSelect', vSelect)

document.addEventListener('DOMContentLoaded', () => {
  let token = document.head.querySelector('meta[name="csrf-token"]').content
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  axios.interceptors.response.use((response) => {
    return response
  }, function (error) {
    new FlashMessage("Une erreur s'est produite", "danger")
    return Promise.reject(error)
  })

  let app = document.querySelector('#vue-app')
  if (app != null) {
    new Vue({
      el: '#vue-app'
    })
  }
})
