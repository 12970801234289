/* eslint-disable */
export default class HeaderMenu {
  constructor() {
    const self = this
    this.$toggler = $('[data-is-menu-toggler]')
    this.$menu = '[data-is-dropdown-menu]'
    this.$toggler.click(function (e) {
      let isOpen = false
      if ($(this).hasClass('open')) {
        isOpen = true
      }
      self.$toggler.removeClass('open')

      if (!isOpen) {
        $(this).addClass('open')
      }
    })
    $(document).click(function (event) {
      if (!$(event.target).closest(self.$menu).length && !$(event.target).closest('[data-is-menu-toggler]').length) {
        self.$toggler.removeClass('open')
      }
    });
  }
}
