<template lang="pug">
  div(class="leading-normal")
    | Nous n'avons pas pu envoyer votre demande:
    ul.mb-0.pl-4(v-if="Object.keys(errors).length")
      li(v-for="error in errors_to_display") {{ error }}
</template>

<script>

export default {
  props: {
    errors: {
      type: Object,
      default () { return {} }
    }
  },

  computed: {
    errors_to_display () {
      let errors = []

      for (let property in this.errors) {
        this.errors[property].forEach(message => {
          errors.push(message)
        })
      }

      return errors
    }
  }
}
</script>
