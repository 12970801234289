<template lang="pug">
  form.flex.flex-wrap.w-col-12(@submit.prevent="onSubmit")
    .w-col-12.px-6(v-if="isSuccess || Object.keys(errors).length")
      .alert(:class="isSuccess ? 'alert-success' : 'alert-danger'")
        div(v-if="isSuccess") {{ successMessage }}
        form-error(:errors="errors" v-else="")

    div(class="w-col-12 sm:w-col-6 px-6")
      div.form-group.form-group--smooth.required
        input(type="text" v-model="contact.lastname" class="form-control input--smooth" :class="{'field_with_errors': errors.lastname, 'input--filled': contact.lastname}" data-is-input-smooth='')
        label(class='label--smooth') Nom

    div(class="w-col-12 sm:w-col-6 px-6")
      div.form-group.form-group--smooth.required
        input(type="text" v-model="contact.firstname" class="form-control input--smooth" :class="{'field_with_errors': errors.firstname, 'input--filled': contact.firstname}" data-is-input-smooth='')
        label(class='label--smooth') Prénom

    div(class="w-col-12 sm:w-col-6 px-6")
      div.form-group.form-group--smooth.required
        input(type="email" v-model="contact.email" class="form-control input--smooth" :class="{'field_with_errors': errors.email, 'input--filled': contact.email}" data-is-input-smooth='')
        label(class='label--smooth') Email

    div(class="w-col-12 sm:w-col-6 px-6")
      div.form-group.form-group--smooth.required
        input(type="text" v-model="contact.phone" class="form-control input--smooth" :class="{'field_with_errors': errors.phone, 'input--filled': contact.phone}" data-is-input-smooth='')
        label(class='label--smooth') Téléphone

    div(class="w-col-12 px-6" v-if="subjects.length > 0")
      .form-group.form-group--smooth
        v-select(
          :options="[placeholderSubject, ...subjects]"
          v-model="subject"
          label="title"
          :searchable="false"
          :filterable="false"
          :clearable="false"
        )

    div(class="w-col-12 px-6")
      div.form-group.form-group--smooth.form-group--smooth-textarea.required
        textarea(class='form-control input--smooth', data-is-input-smooth='', rows='6')
        label(class='label--smooth') Message

    .w-col-12.px-6.mt-12
      .form-group
        .checkbox-inline-wrapper
          .checkbox.abc-checkbox
            input(:id="`rgpd-checkbox-${_uid}`" type="checkbox" name="" v-model="contact.rgpd_terms_accepted")
            label(:for="`rgpd-checkbox-${_uid}`" class="text-xl text-white font-light leading-17")
              span.mr-1 En soumettant ce formulaire, j’accepte que les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me recontacter. Découvrez comment nous recueillons, utilisons et partageons vos données en lisant notre
              a(:href="termsUrl" target="_blank" class='text-white hover:text-primary link-underline active') Politique d’utilisation des données

    div(class="w-col-12 px-6 mt-4 lg:mt-12 text-center")
      div(class="btn btn-outline--gray p-0")
        vue-recaptcha(
          ref="recaptcha"
          size="invisible"
          :sitekey="recaptchaAppKey"
          @verify="onCaptchaVerify"
          @expired="onCaptchaExpired"
        )
        input(
          type="submit"
          class="cursor-pointer relative pin w-full h-full border-0 text-lg font-semibold text-white uppercase bg-transparent rounded-none px-16 py-8 z-10 tracking-wide"
          :disabled="isSubmitting"
          value="Envoyer"
        )

</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import FormError from '@components/FormError'

export default {
  components: {
    VueRecaptcha,
    FormError
  },
  props: {
    recaptchaAppKey: {
      type: String,
      required: true
    },
    termsUrl: {
      type: String,
      required: true
    },
    subjects: {
      type: Array,
      default: () => []
    },
    redirectOnSubmit: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: 'Le message a bien été envoyé'
    },
    contactable: {
      type: Object,
      default () { return {} }
    },
    placeholderSubject: {
      type: Object,
      default () { return { title: 'Objet de votre message', value: '' } }
    }
  },

  data () {
    return {
      subject: this.placeholderSubject,
      contact: {
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        message: '',
        subject: '',
        rgpd_terms_accepted: false,
        contactable_type: this.contactable.type,
        contactable_id: this.contactable.id
      },
      errors: [],
      isSuccess: false,
      isSubmitting: false
    }
  },

  computed: {
    redirectToUri () {
      return `${this.redirectOnSubmit}?from=${window.location.href}`
    }
  },

  watch: {
    subject () {
      if (this.subject) {
        this.contact.subject = this.subject.value
        if (!this.contact.message) {
          this.contact.message = this.subject.message
        }
      }
    }
  },

  methods: {
    onSubmit () {
      this.$refs.recaptcha.execute()
    },

    onCaptchaVerify (recaptchaToken) {
      this.isSubmitting = true
      this.isSuccess = false
      this.$refs.recaptcha.reset()

      this.errors = []

      axios.post('/api/v1/contacts', {
        contact: this.contact,
        'g-recaptcha-response': recaptchaToken
      })
        .then((response) => {
          if (this.redirectOnSubmit) {
            window.location.href = this.redirectToUri
          } else {
            this.isSuccess = true

            this.resetForm()
          }
        })
        .catch(({ response: { data } }) => {
          this.isSubmitting = false
          this.errors = data.errors.data.attributes.errors
        })
        .then(() => {
          this.isSubmitting = false
        })
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },

    resetForm () {
      this.subject = null
      this.contact = this.$options.data().contact
      this.errors = {}
    }
  }
}
</script>
