<template lang="pug">
  form.form-modal.flex.flex-wrap.w-col-12(@submit.prevent="onSubmit")
    .w-col-12.px-6
      .alert.alert-success(v-if="isSuccess")
        | {{ successMessage }}

        button(type="button" class="close" @click="isSuccess = false" aria-label="Close")
          span(aria-hidden="true") &times;

      .alert.alert-danger(v-else-if="Object.keys(errors).length")
        form-error(:errors="errors")

    div(class="w-col-12 px-6")
      div.form-group.required(class="mb-0 flex align-items-center")
        div.form-label(class="font-serif mr-12 mb-0 font-medium text-2base") Civilité
        div.radio-inline-wrapper
          div.radio.abc-radio(v-for="gender in genders" class="inline-flex align-items-center mr-12")
            input(:id="`gender-${gender.value}-${_uid}`" type="radio" name="callradio" v-model="contact.gender" :value="gender.value")
            label(:for="`gender-${gender.value}-${_uid}`" class="font-serif mb-0 font-medium text-2base font-medium leading-17") {{ gender.title }}

    div(class="w-col-12 px-6")
      div.form-group.form-group--smooth
        input(type="text" v-model="contact.lastname" class="form-control input--smooth" :class="{'field_with_errors': errors.lastname, 'input--filled': contact.lastname}" data-is-input-smooth='')
        label(class='label--smooth') Nom

    div(class="w-col-12 px-6")
      .form-group.form-group--smooth
        v-select(
          :options="[placeholderCallbackTime, ...callbackTimes]"
          v-model="callback_time"
          label="title"
          :searchable="false"
          :filterable="false"
          :clearable="false"
        )

    div(class="w-col-12 px-6")
      .form-group.form-group--smooth
        v-select(
          :options="[placeholderProgrammeCategory, ...programmeCategories]"
          v-model="programme_category"
          label="title"
          :searchable="false"
          :filterable="false"
          :clearable="false"
        )

    div(class="w-col-12 px-6")
      div.form-group.form-group--smooth
        input(type="text" v-model="contact.phone" class="form-control input--smooth" :class="{'field_with_errors': errors.phone, 'input--filled': contact.phone}" data-is-input-smooth='')
        label(class='label--smooth') Téléphone

    .w-col-12.px-6
      .form-group
        .checkbox-inline-wrapper
          .checkbox.abc-checkbox
            input(:id="`rgpd-checkbox-${_uid}`" type="checkbox" name="" v-model="contact.rgpd_terms_accepted")
            label(:for="`rgpd-checkbox-${_uid}`" class="text-xl font-light leading-17")
              span.block.mr-1 En soumettant ce formulaire, j’accepte que les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me recontacter. Découvrez comment nous recueillons, utilisons et partageons vos données en lisant notre
              a(:href="termsUrl" target='_blank' class='text-grey-dark hover:text-primary link-underline active') Politique d’utilisation des données

    div(class="w-col-12 px-6 mt-4 text-center")
      div(class="btn btn-gray p-0")
        vue-recaptcha(
          ref="recaptcha"
          size="invisible"
          :sitekey="recaptchaAppKey"
          @verify="onCaptchaVerify"
          @expired="onCaptchaExpired"
        )
        input(
          type="submit"
          class="relative pin w-full h-full border-0 text-lg font-semibold text-white uppercase bg-transparent rounded-none px-16 py-8 z-10 tracking-wide"
          :disabled="isSubmitting"
          value="Envoyer"
        )
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import FormError from '@components/FormError'

export default {
  components: {
    VueRecaptcha,
    FormError
  },
  props: {
    recaptchaAppKey: {
      type: String,
      required: true
    },
    termsUrl: {
      type: String,
      required: true
    },
    callbackTimes: {
      type: Array,
      required: true
    },
    programmeCategories: {
      type: Array,
      required: true
    },
    genders: {
      type: Array,
      required: true
    },
    redirectOnSubmit: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: 'Le message a bien été envoyé'
    },
    placeholderCallbackTime: {
      type: Object,
      default () { return { title: 'Heure de rappel ?', value: '' } }
    },
    placeholderProgrammeCategory: {
      type: Object,
      default () { return { title: 'Type de programme', value: '' } }
    }
  },

  data () {
    return {
      callback_time: this.placeholderCallbackTime,
      programme_category: this.placeholderProgrammeCategory,
      contact: {
        request_type: 'callback',
        gender: '',
        lastname: '',
        phone: '',
        callback_time: '',
        programme_category: '',
        rgpd_terms_accepted: false
      },
      errors: [],
      isSuccess: false,
      isSubmitting: false
    }
  },

  watch: {
    callback_time () {
      if (this.callback_time) {
        this.contact.callback_time = this.callback_time.value
      }
    },

    programme_category () {
      if (this.programme_category) {
        this.contact.programme_category = this.programme_category.value
      }
    }
  },

  methods: {
    onSubmit () {
      this.$refs.recaptcha.execute()
    },

    onCaptchaVerify (recaptchaToken) {
      this.isSubmitting = true
      this.isSuccess = false
      this.$refs.recaptcha.reset()

      this.errors = []

      axios.post('/api/v1/contacts', {
        contact: this.contact,
        'g-recaptcha-response': recaptchaToken
      })
        .then((response) => {
          if (this.redirectOnSubmit) {
            window.location.href = this.redirectOnSubmit
          } else {
            this.isSuccess = true

            this.resetForm()
          }
        })
        .catch(({ response: { data } }) => {
          this.isSubmitting = false
          this.errors = data.errors.data.attributes.errors
        })
        .then(() => {
          this.isSubmitting = false
        })
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },

    resetForm () {
      this.callback_time = null
      this.programme_category = null
      this.contact = this.$options.data().contact
      this.errors = {}
    }
  }
}
</script>
