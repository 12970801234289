export default class NavFixed {
  constructor () {
    this.$header = $('.main-header')
    this.$headerMenu = $('[data-is-main-menu]')

    this.scrollPosition = $(window).scrollTop()

    this.scrollLevelMaxMinimized = 95
    this.scrollLevelMaxOpen = 200

    this.scrollClass = 'header--scroll'
    this.minimizedClass = 'header--minimized'

    this.bindEvents()
  }

  // CHECK SCROLL LEVEL : know which class header should have
  changeScrollClass (currentScroll, oldScroll, maxScrollUp, maxScrollDown) {
    const self = this

    // on top of the window & scroll up : remove fixed position
    if (($(window).scrollTop() < 1) && (currentScroll < oldScroll)) {
      return this.$header.removeClass(self.scrollClass + ' ' + self.minimizedClass)
    } else if ($(window).scrollTop() > maxScrollDown) {
      return this.$header.addClass(self.scrollClass)

    // just after header height
    } else if ($(window).scrollTop() > maxScrollUp) {
      return this.$header.addClass(self.minimizedClass)
    }
  }

  // BIND EVENTS
  bindEvents () {
    const self = this

    // # change header classes on scroll
    // # keep scroll level
    $(window).scroll(function () {
      const scroll = $(window).scrollTop()
      self.changeScrollClass(scroll, self.position, self.scrollLevelMaxMinimized, self.scrollLevelMaxOpen)
      self.position = scroll
    })
  }
};
