<template lang="pug">
  l-map(ref="map" :center="center" :zoom="zoom" :options="mapOptions" v-on="$listeners")
    slot

</template>

<script>
/* global L */

export default {
  props: {
    center: {
      type: [Array, Object],
      required: true
    },
    zoom: {
      type: Number,
      required: true
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    disableInteractions: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    mapOptions () {
      let options = { ...this.options }

      if (this.disableInteractions) {
        options.zoomControl = false
        options.doubleClickZoom = false
        options.dragging = false
        options.keyboard = false
        options.scrollWheelZoom = false
        options.boxZoom = false
      }

      return options
    }
  },

  mounted () {
    L.mapboxGL({
      style: 'https://api.maptiler.com/maps/c9553544-1b52-470a-b24f-cb0406c93db3/style.json?key=cAQ3rAiYq2Vt2Ho6chIm',
      accessToken: 'no-token'
    }).addTo(this.$refs.map.mapObject)
  }
}
</script>
