/* eslint-disable */
import Waypoint from 'waypoints/lib/noframework.waypoints.js'

export default class AnimateScroll {
  constructor() {
    let elements = document.querySelectorAll('[data-animate]')
    Array.prototype.forEach.call(elements, function (el, i) {
      var test = new window.Waypoint({
        element: el,
        handler: function (direction) {
          if (direction === 'down') {
            el.classList.add('in')
          }
          // else {
          //   el.classList.remove('in')
          // }
        },
        offset: '80%'
      })
    })
  }
}
