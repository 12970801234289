/* eslint-disable */
import rallax from 'rallax.js'
import Waypoint from 'waypoints/lib/noframework.waypoints.js'

export default class Parallax {
  constructor () {
    const self = this
    this.$parallaxElement = '[data-is-parallax]'
    this.$parallaxElementAll = document.querySelectorAll(this.$parallaxElement)

    if ($(document).find(self.$parallaxElement).length !== 0) {
      this.initParallax()
      this.bindEvents()
    }
  }

  bindEvents () {
    const self = this

    $(window).on('resize', function () {
      // $(this).scrollTop(0);
      self.initParallax()
    })
  }

  initParallax () {
    const self = this
    Array.prototype.forEach.call(this.$parallaxElementAll, function (el, i) {
      var $parallaxElementSpeed = el.getAttribute('data-is-parallax')
      const options = {
        speed: $parallaxElementSpeed,
        mobilePx: 767
      }
      const parallax = rallax(el, options)
      parallax.stop()

      var test = new window.Waypoint({
        element: el,
        handler: function (direction) {
          if (direction === 'down') {
            parallax.start()
          }
        },
        offset: '100%'
      })
    })
  }
};
