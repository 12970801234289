// #################
// # SCROLL TO
// #################
// Animated scroll To

export default class ScrollTo {
  constructor () {
    const self = this
    this.scrollLevelMax = 1
    this.selectorScrollTo = '[data-trigger-smooth-scroll]'

    if ($(document).find(self.selectorScrollTo).length !== 0) {
      this.bindEvents()
    }
  }

  bindEvents () {
    const self = this

    return $(self.selectorScrollTo).click(function (e) {
      if ($(window).scrollTop() > self.scrollLevelMax) {
        e.preventDefault()
        return self.animateScrollTo($(this), $(this).attr('href'), 450, -70)
      } else {
        e.preventDefault()
        return self.animateScrollTo($(this), $(this).attr('href'), 450, -70)
      }
    })
  }

  // entrée : l'élément sur lequel on clique, élément cible, vitesse(ms), offsetTop (px)
  animateScrollTo (elLink, elTarget, speed, offsetTop) {
    // Au clic sur un élément
    $('html, body').animate({ scrollTop: $(elTarget).offset().top + offsetTop }, speed)
    // Go
    return false
  }
}
