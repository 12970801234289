<template lang="pug">
  custom-map(:center="mapCenter" :zoom="11" :options="{scrollWheelZoom: false}")
    custom-map-marker(
        v-for="(achievement, id) in achievements.data"
        :center="achievement.attributes.latLng"
        :is-dot="true"
        :key="id"
        :is-active="activeMarker === id"
        @popupopen="setActiveMarker(id)"
        @popupclose="setActiveMarker(undefined)"
      )

      l-popup
        a(:href="achievement.attributes.achievement_url")
          div(class="flex")
            div(class="w-col-5")
              img(:src="achievement.attributes.picture_url" class='img-fluid')
            div(class="w-col-7 pl-8")
              .h3(class="font-bold mb-4") {{ achievement.attributes.name }}
              .p--sm(class="tracking-4p font-sans uppercase") {{ achievement.attributes.formatted_city }}
</template>

<script>
/* global L */
export default {
  props: {
    achievements: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      activeMarker: undefined,
      mapCenter: L.latLng(45.736672, 4.83988)
    }
  },

  methods: {
    setActiveMarker (id) {
      this.activeMarker = id
    }
  }
}
</script>
