<template lang="pug">
  l-marker(:lat-lng="center" :icon="icon" v-on="$listeners")
    slot
</template>
<script>
/* global L */
import marker from "images/map/marker.png"
import markerDot from "images/map/marker-dot.png"
import markerDotActive from "images/map/marker-dot-active.png"
export default {
  props: {
    center: {
      type: [Array, Object],
      required: true
    },
    isDot: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    markerIcon () {
      if (this.isDot) {
        if (this.isActive) {
          return markerDotActive
        }
        return markerDot
      } else {
        return marker
      }
    },
    iconAnchor () {
      if (this.isDot) {
        return [14, 14]
      } else {
        return [17, 44]
      }
    },
    icon () {
      return new L.Icon({
        iconUrl: this.markerIcon,
        iconAnchor: this.iconAnchor,
        popupAnchor: [0, -16]
      })
    }
  }
}
</script>
