// ###################
// # LGIHTBOX
// ###############################################################################
export default class Lightbox {
  constructor () {
    let options
    if (options === null) {
      options = {}
    }

    const self = this

    this.selector = '[data-is-lightbox]'
    this.$element = $(self.selector)
    this.selectorList = '.lightbox'

    const defaultOptions = {
      thumbnail: true,
      animateThumb: true,
      showThumbByDefault: true,
      download: false,
      selector: self.selectorList,
      autoplayFirstVideo: false
    }

    this.options = $.extend(true, {}, defaultOptions, options)

    if ($(document).find(self.selector).length > 0) {
      self.init()
    }
  }

  init () {
    const self = this
    this.$element.lightGallery(self.options)
  }
};
