/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// MODULES =====================================================================
import Rails from 'rails-ujs'
import "bootstrap"
import "select2"
import "lightgallery"
import "lg-video"
import "vimeo-froogaloop2"
import "@appPages/vue"

import "stylesheets/application.sass"
import Tooltip from "@utils/tooltip"
import SelectCustom from "@utils/select-custom"
import CustomFileInputList from "@utils/custom-file-input-list"
import ScrollTop from "@utils/scroll-top"
import ScrollTo from "@utils/scroll-to"
import InputSmooth from "@utils/input-smooth"
import ImageObjectFit from "@utils/image-object-fit"
import Sliders from "@utils/sliders"
import FlashMessage from "@utils/flash-messages"
import NavFixed from "@utils/nav-fixed"
import Scrollindicator from "@utils/scroll-indicator"
import MainMenu from "@utils/main-menu"
import Lightbox from "@utils/lightbox"
import Parallax from "@utils/parallax"
import AnimateScroll from "@utils/animate-scroll"
import HeaderMenu from "@utils/header-menu"
import ImageJuxtapose from "@utils/image-juxtapose"

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage

document.addEventListener("DOMContentLoaded", function (event) {
  $(this).scrollTop(0)
  new Tooltip()
  new InputSmooth()
  new SelectCustom()
  new ImageObjectFit()
  new CustomFileInputList()
  new ScrollTop()
  new ScrollTo()
  new Sliders()
  new NavFixed()
  new Scrollindicator()
  new MainMenu()
  new Lightbox()
  new Parallax()
  new AnimateScroll()
  new HeaderMenu()
  new ImageJuxtapose()
})
