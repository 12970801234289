export default class MainMenu {
  constructor () {
    this.toggler = $('[data-trigger-main-menu]')
    this.bodyElement = $('[data-is-main-body]')
    this.classToggle = 'menu--open'

    this.bindEvents()
  }

  bindEvents () {
    const self = this
    this.toggler.on('click', function () {
      self.bodyElement.toggleClass(self.classToggle)
    })
  }
}
