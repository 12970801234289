import Cocoen from 'cocoen'

export default class ImageJuxtapose {
  constructor () {
    const self = this
    this.$cocoenElement = '.cocoen'
    if ($(document).find(self.$cocoenElement).length !== 0) {
      new Cocoen(document.querySelector(self.$cocoenElement))
    }
  }
}
