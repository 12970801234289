export default class ScrollIndicator {
  constructor () {
    this.$scrollIndicator = $('[data-is-scroll-indicator=""]')
    this.bindEvents()
  }

  // BIND EVENTS
  bindEvents () {
    const self = this

    // # change header classes on scroll
    // # keep scroll level
    $(window).scroll(function () {
      var winScroll = document.body.scrollTop || document.documentElement.scrollTop
      var height = document.documentElement.scrollHeight - document.documentElement.clientHeight
      var scrolled = (winScroll / height) * 100
      self.$scrollIndicator.css("width", scrolled + "%")
    })
  }
};
